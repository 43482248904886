import { Box, Text } from '@lendoab/aphrodite';
import Link from 'next/link';
import React, { ReactElement } from 'react';

export default function Custom404(): ReactElement {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            paddingY={['6xl', '30xl']}
        >
            <Text size="medium" marginBottom="medium">
                Den sida finns tyvärr inte.
            </Text>
            <Link href="/">
                <Text size="small" cursor="pointer" style={{ textDecoration: 'underline' }}>
                    Klicka här för att komma tillbaka till startsidan
                </Text>
            </Link>
        </Box>
    );
}
